type Props = {
  data: string;
};

const About = (props: Props) => {
  if (!props.data) return null;

  return <div dangerouslySetInnerHTML={{ __html: props.data }} className="" id="AboutDetails" />;
};

export default About;
