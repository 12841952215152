import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import CloseButton from "../../components/UI/buttons/CloseButton";
import HelpButton from "../../components/UI/buttons/HelpButton";
import { useAppSelector } from "../../state/hooks";
import { RootState } from "../../state/store";
import NetworkIndicator from "./NetworkIndicator";
import ProgressBar from "./ProgressBar";
import SwipeField from "./SwipeField";
import SwipeMessage from "./SwipeMessage";

const Swipe = () => {
  let navigate = useNavigate();

  const cardType = useAppSelector((state: RootState) => state.app.cardType);

  return (
    <MotionDiv className="h-full">
      <div className="h-full">
        <SwipeField type={cardType} />
        <ProgressBar />
        <NetworkIndicator />
        <SwipeMessage />
        <div className="absolute top-0 left-0 z-40">
          <CloseButton onClick={() => navigate("/tutorial", { replace: true })} />
        </div>
        <div className="absolute top-0 right-0 z-40">
          <HelpButton
            onClick={() =>
              // TODO fix link
              window.location.replace("https://soulmade-origin.notion.site/Using-the-Soulmade-card-16f4cc2774f64fe099f81c53925c728c")
            }
          />
        </div>
      </div>
    </MotionDiv>
  );
};

export default Swipe;
