import Balancer from "react-wrap-balancer";
import Modal from "../../../../components/layout/Modal";

type Props = {
  isOpen: boolean;
  setIsOpen: any;
};

const BrowserNotSupportedModal = (props: Props) => {
  return (
    <Modal title="Incompatible browser" isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
      <p className="mt-4 whitespace-pre-wrap">
        <Balancer>Unfortunately, your browser is not supported.</Balancer>
        <br />
        <br />
        <Balancer>Please open the link on another smartphone.</Balancer>
      </p>
    </Modal>
  );
};

export default BrowserNotSupportedModal;
