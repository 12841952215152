import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { customBlocksType } from "../../pages/Start";
import { CardType } from "../types";

export type AppState = {
  invocationIsValid: boolean;
  NFTKey: string;
  cardType: CardType;
  customBlocks: customBlocksType[];
  signature?: string;
  addressReceived?: boolean;
  redirectUrl?: string;
  successScreenData: SuccessScreenDataType;
};

export type SuccessScreenDataType = {
  artist: string;
  bg: string;
  card: string;
  externalButtonLink?: string;
  externalButtonText?: string;
  showAlphafounders?: boolean;
  showDetailButton?: boolean;
  showDetailTabs?: boolean;
  showFakeGotchiPromo?: boolean;
  showImageGallery?: boolean;
  showNFTClaim?: boolean;
  showPatrickVogel?: boolean;
  showWalletInput?: boolean;
};

const initialState: AppState = {
  // invocationIsValid: true,
  invocationIsValid: false,

  // NFTKey: "6b25a32f",  // red one
  // NFTKey: "3e450c61",  // horse
  // NFTKey: "b09a72dq",  // günther
  // NFTKey: "g6t74f23",  // reis

  // NFTKey: "901b89ba-298c-43d5-b55f-3ff50273f5d9",  // dune 1
  // NFTKey: "e7f2ddd5-4289-4007-84ad-49bbfa91b425",  // dune 2
  // NFTKey: "f7404a3b-0f5d-401c-bd75-95f5bcf1641c",  // dune 3
  // NFTKey: "f51b52bb-c147-4e17-92ca-0968129d8d49",  // dune 4

  // NFTKey: "3d79r8el",  // artblocks
  // NFTKey: "e87cg0f7",  // skye aroma
  // NFTKey: "p29s8tb8",  // cryptopunk

  // NFTKey: "7f3c94c5-58fc-4402-b9d8-f22d73c8ed36",  // license.rocks
  // NFTKey: "f7ae90af-21b7-49a2-812d-9f885aa37336",  // moca
  // NFTKey: "8c933e71-8ff2-4723-a1de-6a95b7b64da6",  // justaddsugar

  // NFTKey: "5d9b3c2a-d7c2-4dd4-a600-324beb5c3b40",  // turboslow - showcase
  // NFTKey: "dd6903ee-af9c-4f30-9b40-e04d2cf32da7",  // turboslow - 1/5
  // NFTKey: "ad0ca9b6-9cf4-4ee5-bacc-c6fa954a5a0b",  // turboslow - 2/5
  // NFTKey: "7e8ec97d-a70a-41fe-a201-354865461ee7",  // turboslow - 3/5
  // NFTKey: "0f62539c-1fe8-456a-99f9-b8b73e816c7e",  // turboslow - 4/5
  // NFTKey: "96f5ef8d-45f6-48ff-8b1a-46ee776f2682",  // turboslow - 5/5

  // NFTKey: "5761e967-f922-4ef0-ba36-7c7bbc1ad061",  // overcome - sheNFT

  // NFTKey: "5f3dee64-b2f4-45a3-9776-210f8b5586cf",	// aavegotchis - promocard
  // NFTKey: "36735738-7ccf-4449-b6de-7812bcae54e6",	// aavegotchis - chadgotchi_soju - 001
  // NFTKey: "975f8696-8b4e-4260-9a70-91d00a695f1a",	// aavegotchis - Drippy Boba_Nadiel - 001
  // NFTKey: "40347d09-0b0b-40a5-91ee-3dab2f5fcdad",	// aavegotchis - RiteofPassage_Pepenardo - 001
  // NFTKey: "d264b097-0d88-468b-ab60-38d463054854",	// aavegotchis - ROFL Godlike Apepe_Rare Designer - 001
  // NFTKey: "73c8f4a3-4c6a-4316-a8d1-7b1562d87fab",	// aavegotchis - Aavegotchi Raid_darkfarms - 001
  // NFTKey: "c09011d9-a920-4a4b-8e2a-e87823cd60ab",	// aavegotchis - Gotchi Mode_Xibot - 001
  // NFTKey: "ae6c2df6-bfaf-46ec-af97-903e430e580c",	// aavegotchis - Great Aadventure_Cawito - 001
  // NFTKey: "7862c991-bd07-4360-9049-905cd377fcf1",  // aavegotchis - OPEPEGOTCHI_Onigiri - 001
  // NFTKey: "580a7289-00a5-41cf-9192-8c78cb98fecb",	// aavegotchis - pickled ROFL_mahelartist - 001
  // NFTKey: "80b28e90-3955-4153-ba61-b740626ac708",  // aavegotchis - Wish Bish the Witch OG_Roxiano - 001

  // NFTKey: "0adb9125-0b39-4ed9-bc4c-9520eb0af9d2", // aavegotchis - chadgotchi_soju - 031
  // NFTKey: "d2382535-2663-4e0a-9c6e-1eb22726d9e4", // aavegotchis - Drippy Boba_Nadiel - 035
  // NFTKey: "a78788eb-4510-44cb-9b22-24447af64831", // aavegotchis - RiteofPassage_Pepenardo - 015
  // NFTKey: "859731e1-710c-41fd-a536-2496abb59896", // aavegotchis - ROFL Godlike Apepe_Rare Designer - 048
  // NFTKey: "fb405762-1422-4a4b-9f14-bf775c74ffcc", // aavegotchis - Aavegotchi Raid_darkfarms - 051
  // NFTKey: "55871367-f5f7-413e-9f2c-5f49ee4942f4", // aavegotchis - Gotchi Mode_Xibot - 034
  // NFTKey: "7b7462a5-06fc-41e4-b8b7-b064e462e67c", // aavegotchis - Great Aadventure_Cawito - 019
  // NFTKey: "6567b4d6-fd28-49da-954b-18ca3c79a510", // aavegotchis - OPEPEGOTCHI_Onigiri - 045
  // NFTKey: "e5c323da-b03f-47cc-87af-5d4f380b8e09", // aavegotchis - pickled ROFL_mahelartist - 042
  // NFTKey: "0d66c878-491c-4016-850b-d06574413a10", // aavegotchis - Wish Bish the Witch OG_Roxiano - 038

  // NFTKey: "6cb45e75-11f0-49b6-ae11-43f58ddf84a9", // alphafounders

  // NFTKey: "3e16c39d-d4b4-4eb8-9140-e095617a632e", // NClip

  // NFTKey: "d20b4ef0-fb9b-4d0a-bbee-885032026c77", // Voka - Dagobert
  // NFTKey: "55dfcbea-0ffd-405e-a713-0f8b76033c2e", // Arties Tiger
  // NFTKey: "911025ac-723a-432c-9d40-2cb04ca077e0", // Arties Cryptonaut
  // NFTKey: "4a32fe1d-eb5b-482d-a640-91b466fba2f5", // Bull vs Bear

  // NFTKey: "d6b1461e-5fe4-4fec-a5ff-530a0853df37", // Blockmagazin - ElSalvador - 1/50
  // NFTKey: "4f2c2204-0159-46ae-9996-a56e44fba0b5", // Blockmagazin - ElSalvador - 2/50
  // NFTKey: "b9ccd889-3c8e-4f2d-b828-4906e8a32652", // Blockmagazin - ElSalvador - 3/50
  // NFTKey: "391ad57f-26b1-45d8-8870-43a7d03f0151", // Blockmagazin - ElSalvador - unlimited

  // NFTKey: "5990006f-a32c-4980-b739-aa26f609584a", // Blockmagazin - Tower

  // NFTKey: "6df85a7e-9839-4017-8224-a50bda20d376", // Blockmagazin - To Mars - Spezial
  // NFTKey: "76851589-a371-493d-a9ec-b90b2c969a9c", // Blockmagazin - To Mars - unlimited - Spezial

  // NFTKey: "8741c23f-7680-47de-8046-fbff3d9ee465", // Axie Infinity

  // NFTKey: "17df4d69-de37-4c01-a893-173885d6fcc3", // Neon Tribe

  // NFTKey: "17df4d69-de37-4c01-a893-173885d6fcc3", // Patrick Vogel

  // NFTKey: "7f9a7f26-1169-4c81-a727-f783ac75bb3d", // Pudgy Penguins

  NFTKey: "",

  cardType: "cutout",

  customBlocks: [],

  signature: "",
  addressReceived: false,
  redirectUrl: "",

  successScreenData: {
    card: "",
    bg: "",
    artist: "",
    externalButtonLink: "",
    externalButtonText: "",
    showWalletInput: false,
    showNFTClaim: false,
    showImageGallery: false,
    showDetailButton: false,
    showDetailTabs: false,
    showFakeGotchiPromo: false,
    showAlphafounders: false,
    showPatrickVogel: false,
  },
};

export const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppData: (state, action: PayloadAction<{ nftKey: string; cardType: CardType; customBlocks: customBlocksType[] }>) => {
      state.invocationIsValid = true;
      state.NFTKey = action.payload.nftKey;
      state.cardType = action.payload.cardType;
      state.customBlocks = action.payload.customBlocks;
    },
    setRawData: (state, action: PayloadAction<SuccessScreenDataType>) => {
      state.successScreenData = action.payload;
      console.log("rawDataSuccessScreen", action.payload);
    },
    setSignature: (state, action: PayloadAction<string>) => {
      state.signature = action.payload;
      console.log("signature", action.payload);
    },
    setAddressReceived: (state, action: PayloadAction<boolean>) => {
      state.addressReceived = action.payload;
      console.log("addressReceived", action.payload);
    },
    setRedirectUrl: (state, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
      console.log("redirectUrl", action.payload);
    },
  },
});

export const appReducer = app.reducer;
export const { setAppData, setRawData, setSignature, setAddressReceived, setRedirectUrl } = app.actions;
