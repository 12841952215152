import { AnimatePresence } from "framer-motion";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import WithInvocationCheck from "./components/layout/WithInvocationCheck";
import WithOrientationNotice from "./components/layout/WithOrientationNotice";
import WithSessionExpiredWarning from "./components/layout/WithSessionExpiredWarning";
import Spinner from "./components/UI/Spinner";

import Failure from "./pages/Failure";
import NotFound from "./pages/NotFound";
import Start from "./pages/Start";
import Success from "./pages/Success";
import Swipe from "./pages/Swipe";
import Tutorial from "./pages/Tutorial";

export const validInternalPaths = ["/tutorial", "/swipe", "/success", "/failure"];

const RouterComponent = () => {
  const location = useLocation();

  return (
    <div className="w-full h-full bg-gradient-soulprint">
      <WithSessionExpiredWarning />
      <AnimatePresence exitBeforeEnter>
        <WithInvocationCheck path={location.pathname}>
          <WithOrientationNotice>
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Navigate to="/app" />} />

              <Route path="/tutorial" element={<Tutorial />} />
              <Route path="/swipe" element={<Swipe />} />
              <Route path="/success" element={<Success />} />
              <Route path="/failure" element={<Failure />} />
              <Route path="/app" element={<NotFound />} />

              <Route
                path="/:nftKey"
                element={
                  <ErrorBoundary fallback={<Navigate to="/app" />}>
                    <Suspense fallback={<Spinner />}>
                      <Start />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
            </Routes>
          </WithOrientationNotice>
        </WithInvocationCheck>
      </AnimatePresence>
    </div>
  );
};

export default RouterComponent;
