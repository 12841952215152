import { motion } from "framer-motion";
import { useState } from "react";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";
import { useAppSelector } from "../../state/hooks";
import { RootState } from "../../state/store";

import cross from "../../assets/img/04_verify/Crossbutton.json";
import Balancer from "react-wrap-balancer";

const Failure = () => {
  let navigate = useNavigate();

  const nftKey = useAppSelector((state: RootState) => state.app.NFTKey);

  const staggerFadeIn = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.7,
        delayChildren: 0.5,
        staggerChildren: 0.5,
      },
    },
  };

  const [playing, setPlaying] = useState(false);

  return (
    <MotionDiv className="h-full">
      <div className="flex flex-col items-center justify-between h-full px-8 pt-8 pb-8 text-center text-white bg-gradient-default">
        <div className="">
          <div className="text-2xl font-medium">
            <Balancer>Card could not be verified.</Balancer>
          </div>
          <div className="mt-4 text-lg">
            <Balancer>
              Unfortunately we could not verify the card with your device. Please try a different smartphone or get in touch with Soulmade
              support.
            </Balancer>
          </div>
        </div>

        <motion.div variants={staggerFadeIn} initial="hidden" animate="show" className="flex flex-col-reverse overflow-hidden">
          <motion.div variants={staggerFadeIn} className="flex flex-col items-center mt-4 overflow-hidden">
            <p className="pt-4 text-2xl font-medium">Unknown</p>
          </motion.div>
          <motion.div variants={staggerFadeIn} className="flex flex-col items-center text-lg" onAnimationComplete={() => setPlaying(true)}>
            <Lottie animationData={cross} play={playing} loop={false} speed={0.6} className="w-[14rem] mx-auto h-[14rem] -m-20" />
          </motion.div>
        </motion.div>

        <div className="w-full mt-8">
          <div className="px-6">
            <Button
              onClick={() => navigate(`/${nftKey}`, { replace: true })}
              title="Try Again"
              className="w-full mx-auto"
              primary={false}
            />
            {/* TODO fix url */}
            <Button
              href="http://support.soulmade-origins.com/"
              title="Soulmade Support"
              className="w-full mx-auto mt-4"
              target="_blank"
              rel="noreferrer"
            />
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default Failure;
