import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Fragment } from "react";
import { suspend } from "suspend-react";
import About from "./About";
import NFTDetails from "./NFTDetails";
import PrintDetails from "./PrintDetails";

export type ItemType = string | string[];

type DetailDataType = {
  aboutDetailsEntries: string;
  nftDetailsEntries: ItemType[];
  printDetailsEntries: ItemType[];
  marketplace?: string;
  mediaDownload?: string;
};

type Props = {
  nftKey: string;
  signature: string;
};

// FIXME change in production
const requestUrl = "https://api-dev.soulmade-origins.net/soulmade/nftdetails";

const Details = (props: Props) => {
  const detailData: DetailDataType = suspend(async () => {
    const res = await fetch(requestUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: props.nftKey, signature: props.signature }),
    })
      // .then(sleep(1000))
      .then((response) => {
        if (!response.ok) {
          console.error(response.status);
        }
        return response.json();
      });

    return res;
  }, [props.nftKey, props.signature]);

  if (!detailData) return null;

  return (
    <div className="flex flex-col items-center justify-between h-full px-8 pt-8 pb-8 text-white bg-gradient-default-inverted" id="details">
      <div className="flex flex-col w-full h-full p-6 bg-white bg-opacity-15 rounded-2xl">
        <Tab.Group>
          <Tab.List className="flex justify-between px-4 text-sm">
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={clsx("relative", selected && "font-semibold")}>
                  About
                  {selected && (
                    <motion.hr
                      layoutId="underline"
                      className="absolute inset-x-0 w-full mx-auto border-2 rounded -bottom-1 border-nft-lightBlue bg-nft-lightBlue"
                    />
                  )}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={clsx("relative", selected && "font-semibold")}>
                  NFT Details
                  {selected && (
                    <motion.hr
                      layoutId="underline"
                      className="absolute inset-x-0 w-full mx-auto border-2 rounded -bottom-1 border-nft-lightBlue bg-nft-lightBlue"
                    />
                  )}
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={clsx("relative", selected && "font-semibold")}>
                  Print Details
                  {selected && (
                    <motion.hr
                      layoutId="underline"
                      className="absolute inset-x-0 w-full mx-auto border-2 rounded -bottom-1 border-nft-lightBlue bg-nft-lightBlue"
                    />
                  )}
                </button>
              )}
            </Tab>
          </Tab.List>

          <Tab.Panels className="mt-10 overflow-hidden grow">
            <Tab.Panel className="flex flex-col h-full overflow-scroll text-sm">
              <About data={detailData.aboutDetailsEntries} />
            </Tab.Panel>

            <Tab.Panel className="flex flex-col h-full overflow-scroll text-sm">
              <NFTDetails
                data={detailData.nftDetailsEntries}
                mediaDownload={detailData.mediaDownload}
                marketplace={detailData.marketplace}
              />
            </Tab.Panel>

            <Tab.Panel className="flex flex-col h-full overflow-scroll text-sm">
              <PrintDetails data={detailData.printDetailsEntries} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default Details;
