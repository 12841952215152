import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectivityStatus } from "@soulmade/prismaid";

export type SwipeMessage = {
  title: string;
  message: string;
};

export type SwipeState = {
  connectivityStatus: ConnectivityStatus;
  progress: number;
  isActive: boolean;
  scaleFactor: number;
  swipeMessages: SwipeMessage[];
};

const initialState: SwipeState = {
  connectivityStatus: ConnectivityStatus.ok,
  progress: 0,
  isActive: false,
  scaleFactor: 0,
  swipeMessages: [
    {
      title: "Apply",
      message: "Place card on the screen and hold it with your left thumb.",
    },
  ],
};

export const swipe = createSlice({
  name: "swipe",
  initialState,
  reducers: {
    setConnectivityStatus: (state, action: PayloadAction<ConnectivityStatus>) => {
      state.connectivityStatus = action.payload;
    },
    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    setIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setScaleFactor: (state, action: PayloadAction<number>) => {
      state.scaleFactor = action.payload;
    },
    requestInitialTouchMessage: (state) => {
      if (state.swipeMessages.length === 1) {
        state.swipeMessages.push({
          title: "Swipe",
          message: "Swipe with your finger from bottom up to the circle.",
        });
      }
    },
    addSwipeMessage: (state, action: PayloadAction<SwipeMessage>) => {
      state.swipeMessages.push(action.payload);
    },
    resetSwipeMessage: (state) => {
      state.swipeMessages = [
        {
          title: "Apply",
          message: "Place card on the screen and hold it with your left thumb.",
        },
      ];
    },
  },
});

export const swipeReducer = swipe.reducer;
export const { setConnectivityStatus, setProgress, setIsActive, setScaleFactor, requestInitialTouchMessage, addSwipeMessage, resetSwipeMessage } =
  swipe.actions;
