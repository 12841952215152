import clsx from "clsx";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useState } from "react";
import * as Yup from "yup";
import sendIcon from "../../../assets/img/04_verify/send-address.svg";
import Spinner from "../../../components/UI/Spinner";
import { useAppSelector } from "../../../state/hooks";
import { RootState } from "../../../state/store";

const FormSchema = Yup.object().shape({
  walletAddress: Yup.string().min(5, "too short").max(255, "too long").required("required"),
});

// FIXME change in production
const RequestUrl = "https://api-dev.soulmade-origins.net/nftplatform/addwallet";
// const RequestUrl = "https://api.soulmade-origins.net/nftplatform/addwallet";

const WalletSubmit = () => {
  const nftKey = useAppSelector((state: RootState) => state.app.NFTKey);
  const signature = useAppSelector((state: RootState) => state.app.signature);

  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            transition: { duration: 0.3, delay: 0.1 },
          },
          visible: {
            opacity: 1,
            transition: { duration: 0.1 },
          },
        }}
        initial="hidden"
        animate={isFocused ? "visible" : "hidden"}
        className={clsx("absolute top-0 left-0 w-screen h-screen z-20 touch-none pointer-events-none", "bg-gradient-default")}
      ></motion.div>
      <div className="z-30 w-full text-left">
        <p className="text-sm text-center">
          Collect your soulbound token
          <br />
          for this card!
        </p>

        <Formik
          initialValues={{ walletAddress: "" }}
          validationSchema={FormSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                walletAddress: values.walletAddress.trim(),
                signature: signature,
                id: nftKey,
              }),
            };

            fetch(RequestUrl, requestOptions)
              .then(function (response) {
                if (!(response.status === 200)) {
                  const error = (response && response.statusText) || response.status;
                  return Promise.reject(error);
                }

                return response.text();
              })
              .then((data) => {
                alert("Your wallet address has been stored.\nWe will soon send out your soulbound token.");

                setTimeout(() => {
                  setSubmitting(false);
                  resetForm();
                }, 200);
              })

              .catch((error) => {
                alert("Something went wrong.\nPlease try again");
                setSubmitting(false);
                resetForm();
              });
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <>
              <Form className="" autoComplete="off" spellCheck="false">
                <label htmlFor="walletAddress" className="sr-only">
                  walletAddress
                </label>
                <div className="flex items-center justify-center w-full px-2 py-2 mt-2 border-2 border-white rounded-full">
                  <Field
                    type="text"
                    name="walletAddress"
                    placeholder="Leave your wallet address here"
                    className="w-full text-center bg-transparent placeholder:text-white/80 placeholder:italic"
                    onFocus={(e: any) => {
                      setIsFocused(true);
                      console.log("focused");
                    }}
                    onBlur={(e: any) => {
                      setIsFocused(false);
                      console.log("not focused");
                    }}
                  />
                  <button className="p-2 -m-2" onClick={(e: any) => handleSubmit(e)} disabled={isSubmitting}>
                    <img src={sendIcon} className="w-8 h-8" alt="send" />
                  </button>
                </div>
                <div className="h-5">
                  <ErrorMessage name="walletAddress" component="div" className="text-sm" />
                </div>
              </Form>
              {isSubmitting ? <Spinner /> : null}
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default WalletSubmit;
