import { ItemType } from "..";

type Props = {
  data: ItemType[];
};

const PrintDetails = (props: Props) => {
  if (!props.data) return null;

  return (
    <>
      <dl className="space-y-3">
        {props.data.map((entry, index) => {
          if (entry.length === 1) {
            return (
              <div className="grid items-center px-4 py-2 rounded-full bg-white/20" key={index} id="PrintDetails">
                {entry[0]}
              </div>
            );
          } else {
            return (
              <dl className="grid items-center grid-cols-2 gap-2 px-4 py-2 rounded-full bg-white/20" key={index} id="PrintDetails">
                <dt className="line-clamp-2">{entry[0]}</dt>
                <dd className="line-clamp-2" dangerouslySetInnerHTML={{ __html: entry[1] }}></dd>
              </dl>
            );
          }
        })}
      </dl>
    </>
  );
};

export default PrintDetails;
