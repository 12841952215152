import { motion } from "framer-motion";
import { ReactNode } from "react";

const variants = {
  initialVisible: "initialVisible",
  initialHidden: "initialHidden",
  initialOnLeft: "initialOnLeft",
  initialOnRight: "initialOnRight",
  initialOnTop: "initialOnTop",
  initialOnBottom: "initialOnBottom",

  fadeIn: "fadeIn",
  fadeOut: "fadeOut",
  fadeInFast: "fadeInFast",
  fadeOutFast: "fadeOutFast",

  slideInHorizontal: "slideInHorizontal",
  slideInVertical: "slideInVertical",

  slideOutToLeft: "slideOutToLeft",
  slideOutToRight: "slideOutToRight",
  slideOutToTop: "slideOutToTop",
  slideOutToBottom: "slideOutToBottom",
};

export type MotionDivVariant = keyof typeof variants;

interface Props {
  children?: ReactNode;
  initial?: MotionDivVariant;
  animate?: MotionDivVariant;
  exit?: MotionDivVariant;
  className?: any;
  style?: any;
}

const MotionDiv = (props: Props) => {
  const variants = {
    // initial
    initialVisible: {
      opacity: 1,
      x: "0%",
      y: "0%",
    },
    initialHidden: {
      opacity: 0,
    },
    initialOnLeft: {
      x: "-100%",
    },
    initialOnRight: {
      x: "100%",
    },
    initialOnTop: {
      y: "-100%",
    },
    initialOnBottom: {
      y: "100%",
    },
    // fade
    fadeIn: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    fadeOut: {
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    fadeInFast: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
    fadeOutFast: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    // slideIn
    slideInHorizontal: {
      x: "0%",
      transition: {
        duration: 0.5,
      },
    },
    slideInVertical: {
      y: "0%",
      transition: {
        duration: 0.5,
      },
    },
    // slideOut
    slideOutToLeft: {
      x: "-100%",
      transition: {
        duration: 0.5,
      },
    },
    slideOutToRight: {
      x: "100%",
      transition: {
        duration: 0.5,
      },
    },
    slideOutToTop: {
      y: "-100%",
      transition: {
        duration: 0.5,
      },
    },
    slideOutToBottom: {
      y: "100%",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div
      variants={variants}
      initial={props.initial || "initialHidden"}
      animate={props.animate || "fadeIn"}
      exit={props.exit || "fadeOut"}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </motion.div>
  );
};

export default MotionDiv;
