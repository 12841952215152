import clsx from "clsx";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Balancer from "react-wrap-balancer";
import sleep from "sleep-promise";
import { suspend } from "suspend-react";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";
import { useAppDispatch } from "../../state/hooks";
import { setAppData } from "../../state/slices/app";
import { CardType } from "../../state/types";

import bg from "../../assets/img/00_general/bg.jpg";

// FIXME change in production
const requestUrl = "https://api-dev.soulmade-origins.net/soulmade/validateid";

export type customBlocksType = "isNFTTwin" | "isNeonTribe" | "isPOAP" | "isAlphafounders";

type startDataType = {
  title: string;
  startScreenImageUrl: string;
  logo: string;
  startButtonText: string;
  cardType: CardType;
  description: string;
  customBlocks?: customBlocksType[];
  isExpired?: boolean;
};

const Start = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  let { nftKey } = useParams();

  const startData: startDataType = suspend(async () => {
    const res = await fetch(requestUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: nftKey }),
    })
      // .then(sleep(1000))
      .then((response) => {
        if (!response.ok) {
          window.location.href = "/app";
        }
        return response.json();
      });

    console.log(res);

    return res;
  }, []);

  const needsCustomBlock = (block: customBlocksType) => {
    if (startData.customBlocks?.includes(block)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!startData.cardType) return;

    dispatch(
      setAppData({
        nftKey: nftKey ?? "",
        cardType: (startData.cardType.toLowerCase() as CardType) || "cutout",
        customBlocks: startData.customBlocks || [],
      })
    );
  }, [startData]);

  return (
    <MotionDiv className="h-full">
      <div
        className={clsx(
          "flex flex-col items-center  h-full pt-8 pb-8 text-white bg-center bg-cover px-8",
          startData ? "justify-between" : "justify-center"
        )}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <img src={startData.logo} alt="Logo" className="h-18 drop-shadow-xl" />

        {startData && (
          <>
            <div className="grid items-center justify-center py-2 overflow-hidden h-1/2" id="startScreenImage">
              <img src={startData.startScreenImageUrl} className="object-contain h-full overflow-hidden rounded-2xl " alt="" />
            </div>

            <h1 className="w-full text-2xl font-medium leading-relaxed text-center">
              <Balancer>{startData.title}</Balancer>
              <br />
              <Balancer>{startData.description}</Balancer>
            </h1>

            {!startData.isExpired && (
              <div className="w-full px-6">
                <Button
                  onClick={() => {
                    navigate("/tutorial");
                  }}
                  title={startData.startButtonText}
                  className="w-full"
                />
              </div>
            )}

            {needsCustomBlock("isAlphafounders") && (
              <div className="w-full px-6 pt-8 text-sm text-center ">
                <a
                  href="https://www.alphafounders.xyz"
                  target="_blank"
                  rel="noreferrer"
                  className="w-full px-2 py-2 underline underline-offset-2"
                >
                  What is alphafounders?
                </a>
              </div>
            )}
            {needsCustomBlock("isNeonTribe") && (
              <div className="w-full px-6 pt-8 text-sm text-center ">
                <a
                  href="https://twitter.com/realneontribe"
                  target="_blank"
                  rel="noreferrer"
                  className="w-full px-2 py-2 underline underline-offset-2"
                >
                  Follow Neon Tribe on twitter
                </a>
              </div>
            )}
            {!needsCustomBlock("isNFTTwin") && (
              <div className="w-full px-6 pt-2 text-sm text-center">
                <a
                  href="https://soulmade-origins.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="w-full px-2 py-2 underline underline-offset-2"
                >
                  What is Soulmade Origins?
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </MotionDiv>
  );
};

export default Start;
