import { ReactNode } from "react";
import clsx from "clsx";

type Props = {
  children: ReactNode;
  className?: string;
  small?: boolean;
  noPadding?: boolean;
};

const Card = (props: Props) => {
  return (
    <div
      className={clsx(
        "overflow-hidden text-center bg-white rounded-lg shadow-md whitespace-pre-wrap",
        { "px-4 py-5 sm:p-6": !props.small && !props.noPadding },
        { "px-3 py-3 sm:p-6": props.small && !props.noPadding },
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

export default Card;
