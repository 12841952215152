import { ItemType } from "..";
import Button from "../../../../components/UI/buttons/Button";

type Props = {
  data: ItemType[];
  mediaDownload?: string;
  marketplace?: string;
};

const NFTDetails = (props: Props) => {
  if (!props.data) return null;

  return (
    <>
      <dl className="space-y-3" id="NFTDetails">
        {props.data.map((entry, index) => {
          if (entry.length === 1) {
            return (
              <div className="grid items-center grid-cols-2 gap-2 px-4 py-2 rounded-full bg-white/20" key={index}>
                <dt className="line-clamp-2">{entry[0]}</dt>
              </div>
            );
          } else {
            return (
              <div className="grid items-center grid-cols-2 gap-2 px-4 py-2 rounded-full bg-white/20" key={index}>
                <dt className="line-clamp-2">{entry[0]}</dt>
                <dd className="break-all line-clamp-2" dangerouslySetInnerHTML={{ __html: entry[1] }}></dd>
              </div>
            );
          }
        })}
      </dl>

      <div className="mt-auto">
        {props.mediaDownload && (
          <Button href={props.mediaDownload} title="Media Download" className="w-full mt-10" target="_blank" rel="noreferrer" />
        )}

        {props.marketplace && (
          <Button title="Go to Marketplace" href={props.marketplace} className="w-full mt-4" target="_blank" rel="noreferrer" />
        )}
      </div>
    </>
  );
};

export default NFTDetails;
