import SensitivityModal from "./SensitivityModal";

interface Props {
  isOpen: boolean;
  setIsOpen: any;
}

const PointerSpeedModal = (props: Props) => {
  return (
    <SensitivityModal
      title="Activate the <b>Pointer&nbsp;speed</b> on your smartphone."
      p1='Open "Settings" on your device'
      p2='Select "Language and keyboard"'
      p3='Select "Pointer&nbsp;speed" and adjust to 100%'
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
    />
  );
};

export default PointerSpeedModal;
