import clsx from "clsx";
import MotionDiv from "../../components/motion/MotionDiv";

import bg from "../../assets/img/00_general/bg.jpg";
import logo from "../../assets/img/01_start/start_logo_soulmade.svg";

const NotFound = () => {
  return (
    <MotionDiv className="h-full">
      <div
        className={clsx("flex flex-col items-center  h-full pt-8 pb-8 text-white bg-center bg-cover px-8", "justify-center")}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <img src={logo} alt="Logo" className="h-18 drop-shadow-xl" />
      </div>
    </MotionDiv>
  );
};

export default NotFound;
