import Button from "../../../../components/UI/buttons/Button";
import Modal from "../../../../components/layout/Modal";
import Balancer from "react-wrap-balancer";

type Props = {
  isOpen: boolean;
  setIsOpen: any;
};

const ShouldAddToHomeScreenModal = (props: Props) => {
  return (
    <Modal title="Display too small" isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
      <p className="mt-4 whitespace-pre-wrap">
        <Balancer>This smartphone is too small.</Balancer>
        <br />
        <br />
        <Balancer>Some information may not be visible.</Balancer>
        <br />
        <br />
        <Balancer>We recommend adding it to your home screen or trying it on a larger device.</Balancer>
      </p>
      <div className="mt-6">
        <Button title="Continue anyway" onClick={() => props.setIsOpen(false)} inverted={true} />
      </div>
    </Modal>
  );
};

export default ShouldAddToHomeScreenModal;
