import Lottie from "react-lottie-player";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";
import { useAppSelector } from "../../state/hooks";
import { RootState } from "../../state/store";
import Details from "./Details";
import WalletSubmit from "./WalletSubmit";

import arrow from "../../assets/img/04_verify/arrow.json";
import check_light from "../../assets/img/04_verify/lottie-soulmade.json";

import promoReveal from "../../assets/img/04_verify/promo_reveal.png";
import promoWelcome from "../../assets/img/04_verify/promo_welcome.png";

import alphaReveal from "../../assets/img/04_verify/alpha_reveal.png";

import neontribeLogo from "../../assets/img/04_verify/poap_logo_neontribe.png";
import neontribeReveal from "../../assets/img/04_verify/poap_neontribe.png";

const Success = () => {
  const nftKey = useAppSelector((state: RootState) => state.app.NFTKey);

  const successScreenData = useAppSelector((state: RootState) => state.app.successScreenData);
  const signature = useAppSelector((state: RootState) => state.app.signature);
  const addressReceived = useAppSelector((state: RootState) => state.app.addressReceived);
  const redirectUrl = useAppSelector((state: RootState) => state.app.redirectUrl);

  if (!nftKey) return null;
  if (!successScreenData) return null;
  if (!signature) return null;

  // TODO cleanup layout
  // especially in regards to the layout differences nft vs. POAP

  return (
    <MotionDiv className="h-full overflow-scroll">
      <div className="flex flex-col items-center justify-between min-h-full px-8 pt-8 pb-8 text-center text-white bg-gradient-default">
        <div className="text-xl font-semibold text-center">Successfully verified</div>

        {successScreenData.card && (
          <div className="w-screen mt-4 grid grid-cols-[1fr,auto,1fr]">
            <div className="col-span-3 col-start-1 row-start-1 overflow-hidden h-[40vh]">
              {successScreenData.bg && <img src={successScreenData.bg} className="object-cover w-full h-full" alt="" />}
            </div>

            <div className="col-start-2 row-start-1 overflow-hidden h-[40vh] max-w-[80vw]">
              <img src={successScreenData.card} className="object-contain h-full" alt="" />
            </div>

            <div
              className="w-full h-10 col-start-2 row-start-2 overflow-hidden bg-center bg-no-repeat bg-contain"
              style={{ backgroundImage: `url(${successScreenData.artist})` }}
            ></div>
          </div>
        )}

        {successScreenData.showFakeGotchiPromo && (
          <div>
            <img src={promoReveal} className="object-contain h-[35vh] mx-auto" alt="" />
            <img src={promoWelcome} className="object-contain h-20" alt="" />
            <p className="mt-4 font-medium">
              Powered by{" "}
              <a href="https://soulmade-origins.com" target="_blank" rel="noreferrer" className="underline">
                Soulmade Origins
              </a>
            </p>
          </div>
        )}
        {successScreenData.showAlphafounders && (
          <div>
            <img src={alphaReveal} className="object-contain h-[35vh] mx-auto" alt="" />
            <p className="mt-4 font-medium">
              Powered by{" "}
              <a href="https://soulmade-origins.com" target="_blank" rel="noreferrer" className="underline">
                Soulmade Origins
              </a>
            </p>
          </div>
        )}
        {successScreenData.showPatrickVogel && (
          <div>
            <img src={neontribeReveal} className="object-contain h-[35vh] mx-auto" alt="" />
            <img src={neontribeLogo} className="object-contain h-16 mx-auto" alt="" />
            <p className="mt-4 font-medium">
              Powered by{" "}
              <a href="https://soulmade-origins.com" target="_blank" rel="noreferrer" className="underline">
                Soulmade Origins
              </a>
            </p>
          </div>
        )}

        <Lottie animationData={check_light} loop={false} play={true} speed={1} className="h-24 mx-auto" />

        {successScreenData.showWalletInput && (
          <>
            {addressReceived ? (
              signature ? (
                <div className="text-sm text-center">
                  Your wallet address has been stored.
                  <br />
                  We will soon send out your Soulbound Token.
                </div>
              ) : (
                <div className="text-sm text-center">
                  Soulbound Token has been sent.
                  <br />
                  Check your wallet.
                </div>
              )
            ) : (
              <WalletSubmit />
            )}

            <Lottie animationData={arrow} loop={true} play={true} speed={1} className="w-12 h-12 mx-auto" />
          </>
        )}

        {successScreenData.showNFTClaim && (
          <div className="w-full px-6 space-y-4 text-center">
            <Button
              href="https://nftverse.club/giveaway/2"
              title="Claim your NFT"
              className="w-full mx-auto"
              target="_blank"
              rel="noreferrer"
            />
          </div>
        )}

        {successScreenData.showImageGallery && (
          <div className="w-full px-6 space-y-4 text-center">
            <Button
              href="https://thecrewcall.de/debrief1337/"
              title="Enter debrief"
              className="w-full mx-auto"
              target="_blank"
              rel="noreferrer"
            />
          </div>
        )}

        {successScreenData.showDetailButton && (
          <div className="w-full px-6 space-y-4 text-center">
            <Button
              onClick={() => {
                const anchorEl = document.getElementById("details");
                if (anchorEl) {
                  anchorEl.scrollIntoView({ behavior: "smooth" });
                }
              }}
              title="Show Origin"
              className="w-full mx-auto"
            />
          </div>
        )}

        {successScreenData.showFakeGotchiPromo && (
          <div className="w-full px-6 text-center">
            <div>
              <p className="text-sm uppercase">
                high quality art prints
                <br />
                with a digital touch to
                <br />
                experience nft origin & value
              </p>
            </div>

            <Button
              onClick={() => {
                window.location.replace("https://poap.website/kj291lkhzt39627ajztn");
              }}
              title="Claim exclusive POAP"
              className="w-full mx-auto mt-6"
            />

            <div className="mt-6 text-sm ">
              <a href="https://www.fakegotchis.com" target="_blank" rel="noreferrer">
                www.fakegotchis.com
              </a>
            </div>
          </div>
        )}

        {successScreenData.showAlphafounders && (
          <div className="w-full px-6 text-center">
            <div>
              <p className="text-sm uppercase">
                high quality art prints
                <br />
                with a digital touch to
                <br />
                experience nft origin & value
              </p>
            </div>

            <Button
              onClick={() => {
                if (redirectUrl) {
                  window.location.replace(redirectUrl);
                } else {
                  window.location.replace("https://poap.website/");
                  console.log("no redirectUrl");
                }
              }}
              title="Claim exclusive POAP"
              className="w-full mx-auto mt-6"
            />
          </div>
        )}
        {successScreenData.showPatrickVogel && (
          <div className="w-full px-6 text-center">
            <div>
              <p className="text-sm uppercase">
                high quality art prints
                <br />
                with a digital touch to
                <br />
                experience nft origin & value
              </p>
            </div>

            <Button
              onClick={() => {
                if (redirectUrl) {
                  window.location.replace(redirectUrl);
                } else {
                  window.location.replace("https://poap.website/");
                  console.log("no redirectUrl");
                }
              }}
              title="Claim exclusive POAP"
              className="w-full mx-auto mt-6"
            />

            <div className="mt-6 text-sm ">
              <a href="www.twitter.com/patrickcandoit" target="_blank" rel="noreferrer">
                Patrick Vogel
              </a>
            </div>
          </div>
        )}

        {successScreenData.externalButtonText && (
          <div className="w-full px-6 text-center">
            <Button
              href={successScreenData.externalButtonLink}
              title={successScreenData.externalButtonText}
              className="w-full mx-auto"
              target="_blank"
              rel="noreferrer"
            />
          </div>
        )}
      </div>

      {successScreenData.showDetailTabs && signature && <Details nftKey={nftKey} key={nftKey} signature={signature} />}
    </MotionDiv>
  );
};

export default Success;
